const __request = require(`./__request/flight-admin/__request_contentType_json`);

// 订单来源枚举列表
//   POST /buyer/dom/manage/orderSourceEnumList
//   接口ID：69144936
//   接口地址：https://www.apifox.cn/link/project/624797/apis/api-69144936
const buyer_dom_manage_orderSourceEnumList = (data) => {
let pParameter = {
    method: 'post',
    urlSuffix:"/buyer/dom/manage/orderSourceEnumList",
    data:data
  }

  return __request(pParameter)
}
export default buyer_dom_manage_orderSourceEnumList
