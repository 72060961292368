const Q = require(`q`);
const _ = require(`underscore`);
const __request = require(`./__request/flight-admin/__request_contentType_json`);

// 订单列表查询
// 接口文档地址：http://183.58.24.99:9999/swagger-ui.html#/domestic-buyer-manage-order-controller
const flight_domestic_buyer_manage_order_list = (pParameter) => {
  if (!pParameter) pParameter = {};
  const p = {
    method: 'post',
    // urlSuffix: 'flight/domestic/buyer/manage/order/list',
    urlSuffix:'buyer/dom/manage/orderList',
    data: pParameter
  };
  return Q.when()
    .then(function () {
      return __request(p);
    })
    .then(function (data) {
      _.each(data.pageResult.pageData, function (m) {
        m.orderNo_text = m.orderNo || `--`;
        m.pnr_text = m.pnr || `--`;
      });
      return data;
    })
    .catch(function (ex) {
      console.error(ex);
      if (ex) {
        if (ex.stack) {
          console.error(ex.stack);
        }
      }
      return Q.reject(ex);
    })
    ;
};
module.exports = flight_domestic_buyer_manage_order_list;
