import _ from 'underscore';
import moment from 'moment';
import Q from 'q';
import ListTimer from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/yinzhilv/list-timer'

import flight_domestic_buyer_manage_order_list from '@/lib/data-service/flight/flight_domestic_buyer_manage_order_list'
import buyer_dom_manage_orderSourceEnumList from '@/lib/data-service/flight/buyer_dom_manage_orderSourceEnumList'
import flight_domestic_airlines from '@/lib/data-service/flight/flight_domestic_airlines'
import order_cancel from '@/lib/data-service/flight/flight_domestic_buyer_white_order_cancel'
import scrollTop from '@/lib/common-service/scrollTop'
import recordCount from '@/lib/data-service/flight/flight_domestic_buyer_manage_order_recordCount'
import AirlineSelect from '@/page/distributor/air-ticket/components/airlineSelect'
import plat_dom_getAllProductType from '@/lib/data-service/flight/plat_dom_getAllProductType'
import { awaitWrap } from "@/page/distributor/train/front/common/unit";
import RouterLinkButton from 'yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/router-link-button/1.0.1/index.vue'
import RefundChangeLabel from '@/page/distributor/air-ticket/admin/component/refund-change-label/index.vue'
import plat_dom_getPartProductType from '@/lib/data-service/flight/plat_dom_getPartProductType'

export default {
  components: { AirlineSelect, RefundChangeLabel },
  data() {
    return {
      a: '',
      //timeUpbox: null,
      //timeUpboxHeight: null,
      searchForm: {
        pnr: ``,
        orderNo: ``,
        ticketNumber: ``,
        flightType: '',
        airCode: ``,
        psgName: ``,
        productType: '',
        orderSource: '',//订单来源

        __orderTimeStart: '',
        __orderTimeEnd: ``,

        orderStatus: `1`,
      },
      dialogVisible: false,
      cancelOrderNo: '',
      pageResult: {
        currentPage: 1,
        pageSize: 10,
        pageData: [],
        totalCount: 0,
      },

      data_flight_domestic_airlines: {
        pageResult: {
          pageData: [],
        }
      },
      data_flight_domestic_buyer_manage_order_states: {
        pageResult: {
          pageData: [],
        }
      },
      recordCount: [],
      isFirst: true,
      loading: true,
      NavItemActive: 0,
      pCurrentPage: 1,
      flightTypeList: [
        { label: '全部', value: '' },
        { label: '单程', value: '1' },
        { label: '往返', value: '2' }
      ],

      deferInitDataCompleted: '',
      deferResolve: '',
      aListTimer: null,

      productTypeList: [],
      selectProductTypeList: [
        /*{ label: '全部', value: '' },
        { label: '平台优选', value: '1' },
        { label: '官网', value: '2' },
        { label: '低价推荐', value: '3' }*/
      ],
      orderSourceList: [
        {
          value: '',
          msg: '全部'
        }
      ],//订单来源枚举值
    };
  },

  computed: {
    orderTimeStart: {
      get() {
        const __this = this;
        const flag = moment(__this.searchForm.__orderTimeStart).isValid();
        let s = ``;
        if (flag) {
          const d = moment(__this.searchForm.__orderTimeStart);
          s = d.format(`YYYY/MM/DD`);
        }
        return s;
      }
    },
    orderTimeEnd: {
      get() {
        const __this = this;
        const flag = moment(__this.searchForm.__orderTimeEnd).isValid();
        let s = ``;
        if (flag) {
          const d = moment(__this.searchForm.__orderTimeEnd);
          s = d.format(`YYYY/MM/DD`);
        }
        return s;
      }
    },
    getTime() {
      let _this = this
      return function (index) {
        if (_this.searchForm.__orderTimeStart) {
          return _this.searchForm.__orderTimeStart[index]
        } else {
          return ''
        }
      }
    },
  },
  methods: {
    async init () {
      const __this = this;
      const data_flight_domestic_airlines = await flight_domestic_airlines();
      __this.data_flight_domestic_airlines = data_flight_domestic_airlines;
      __this.__btn_reset_click();
    },

    async __data_bind_list(pParameter) {
      const __this = this;
      if (!pParameter) pParameter = {};
      const currentPage = pParameter.currentPage || __this.pageResult.currentPage;
      const pageSize = pParameter.pageSize || __this.pageResult.pageSize;
      const pnr = __this.searchForm.pnr;
      const orderNo = __this.searchForm.orderNo;
      const ticketNumber = __this.searchForm.ticketNumber;
      const airCode = __this.searchForm.airCode;
      const psgName = __this.searchForm.psgName;
      const orderStatus = __this.searchForm.orderStatus;
      let _fromData = this.searchForm
      const orderTimeStart = __this.getTime(0)
      const orderTimeEnd = __this.getTime(1)
      const orderSource = __this.searchForm.orderSource;
      const productType = __this.searchForm.productType
      let data = {
        currentPage: currentPage,
        pageSize: pageSize,
        pnr: pnr,
        orderNo: orderNo,
        ticketNumber: ticketNumber,
        airCode: airCode,
        psgName: psgName,
        orderStatus: orderStatus,
        orderTimeStart: orderTimeStart,
        placeAnOrderPerson: _fromData.placeAnOrderPerson,
        orderTimeEnd: orderTimeEnd,
        flightNo: _fromData.flightNo,
        flightType: _fromData.flightType,
        contactTel: _fromData.contactTel,
        psgCardNo: _fromData.psgCardNo,
        orderSource: orderSource,
        productType
      };

      let pass = false;
      for (let i in data) {
        if (['currentPage', 'pageSize', 'orderStatus'].indexOf(i) === -1) {
          if (data[i]) {
            pass = true
          }
        }
      }
      if (!pass) {
        this.$message('请选择筛选条件');
        return
      }

      __this.loading = true;

      try {
        recordCount(data).then(res => {
          const list = res.records;
          this.recordCount = list;
          if (this.isFirst) {
            this.isFirst = false;
            const status = Number(__this.$route.query.status);
            if (status) {
              for (let i = 0; i < list.length; i++) {
                const item = list[i];
                if (item.status === status) {
                  __this.__btn_tab_click(item, i);
                  return;
                }
              }
            } else {

            }
          };
          flight_domestic_buyer_manage_order_list(data).then((result) => {
            __this.loading = false;
            let pageData = result.pageResult.pageData;
            pageData.forEach(value => {
              if (value.ticketEfficiency === '' || value.ticketEfficiency === null) {
                value.ticketEfficiencyResult = ''
              } else if (value.ticketEfficiencyStatus === 3) {
                value.ticketEfficiencyResult = value.ticketEfficiency
              } else if (value.ticketEfficiencyStatus === 2) {
                value.ticketEfficiencyResult = value.ticketEfficiency
              } else if (value.ticketEfficiencyStatus === 1) {
                value.ticketEfficiencyResult = ''
              }
            })
            __this.pageResult.pageData = pageData;
            __this.pageResult.totalCount = result.pageResult.totalCount;
            __this.deferResolve()
            scrollTop(500)
          }).catch((err) => {
            __this.loading = false;
          });
        }).catch(() => {

        });
      } catch{
        __this.loading = false;
        __this.pageResult.pageData = []
        __this.pageResult.totalCount = 0
      }
    },

    getPartProductType() {
      plat_dom_getPartProductType().then(res => {
        let selectProductTypeList = res.producyTypes
        selectProductTypeList.unshift({
          code: '',
          msg: '全部'
        })
        this.selectProductTypeList = selectProductTypeList
      })
    },

    __el_pagination_current_change_event_handler(pCurrentPage) {
      const __this = this;
      __this.pageResult.currentPage = pCurrentPage
      __this.__data_bind_list();
    },

    setOrdersNo(ordersNo, toString, psgList, manualStatus, orderSource) {
      if (toString === 'air-ticket-admin-ticketChanging' && orderSource === 4) {
        this.$message({
          type: "warning",
          message: `订单号${ordersNo}为线下订单，暂不支持线上申请改签，请联系客服处理。`,
          offset: 80
        })
        return
      }
      if (toString === 'air-ticket-admin-dishonour' && orderSource === 4) {
        this.$message({
          type: "warning",
          message: `订单号${ordersNo}为线下订单，暂不支持线上申请退票，请联系客服处理。`,
          offset: 80
        })
        return
      }
      let psgType
      if (psgList) {
        let psg = psgList.map(item => {
          return item.psgType
        })
        if (psg.indexOf(1) != -1) {
          psgType = 1
        } else {
          manualStatus == 1 ? psgType = 3 :
            psgType = 2
        }
      }
      this.$router.push({
        name: toString,
        query: {
          ordersNo,
          psgType
        },params: {
          refresh: toString === 'air-ticket-admin-ticketChanging' ? true : false
        }
      })
    },
    apply(orderNo) {
      this.$router.push({ name: 'air-ticket-admin-strokeOrder' });
    },
    toApplySupplementRefund(orderNo) {
      this.$router.push({
        name: 'air-ticket-admin-apply-supplement-refund',
        query: {
          orderNo: orderNo
        }
      });
    },
    cancel(ordersNo) {
      let parame = {
        orderNo: ordersNo,
        remark: ''
      }
      order_cancel(parame).then(res => {
        res.isSuccess ?
          this.$message({ type: "warning", message: "操作成功！" }) : ''
        this.__data_bind_list({ currentPage: this.pCurrentPage });
      })
    },
    __el_pagination_size_change_event_handler(pPageSize) {
      const __this = this;
      __this.pageResult.pageSize = pPageSize
      __this.__data_bind_list();
    },

    __btn_search_click() {
      const __this = this;
      __this.__data_bind_list({ currentPage: 1 });
    },

    __btn_reset_click() {
      const __this = this;

      _.each(__this.searchForm, function (property_value, property_name) {
        __this.searchForm[property_name] = ``;
      });
      __this.searchForm.orderStatus = `1`;
      const orderTimeEnd = moment().format('YYYY/MM/DD')
      const orderTimeStart = moment().subtract(1,'months').format('YYYY/MM/DD')
      __this.searchForm.__orderTimeStart = [orderTimeStart, orderTimeEnd]
      __this.__data_bind_list();
    },

    __btn_tab_click(pItem, pIndex) {
      const __this = this;
      __this.searchForm.orderStatus = pItem.status;
      __this.NavItemActive = pIndex;
      this.pageResult.currentPage = 1
      __this.__btn_search_click();
    },
    // offHeight(e) {
    //   this.timeUpboxHeight = e.target.offsetParent.offsetHeight;
    // },
    _initTimer() {
      const __this = this
      let deferInitDataCompleted = this.deferInitDataCompleted
      deferInitDataCompleted.then(res => {
        const aListTimer = new ListTimer().init({
          interval: `minute`,
          get_data_source() {
            const entity_list = __this.pageResult.pageData;

            const d = {
              entity_list,
            };
            return Q.resolve(d);
          },
          handler(args) {
            const entity = args.entity;
            const index_entity = args.index_entity;
            const index_setInterval = args.index_setInterval
            const utils = args.utils;
            const get_process_data_101 = utils.get_process_data_101
            if (index_setInterval === 0) {
              return
            }
            if (entity.ticketEfficiency === '' || entity.ticketEfficiency === null) {
              entity.ticketEfficiencyResult = ''
            } else if (entity.ticketEfficiencyStatus === 3) {
              entity.ticketEfficiencyResult = entity.ticketEfficiency
            } else if (entity.ticketEfficiencyStatus === 2) {
              entity.ticketEfficiencyResult++
            } else if (entity.ticketEfficiencyStatus === 1) {
              entity.ticketEfficiencyResult = ''
            }
            //entity.custom_value_001 = entity.custom_value_001 + 1;

            // const value_101 = entity.value_101;
            //
            // const process_data_101 = get_process_data_101({
            //   date_time_format_string: value_101,
            // });
            // let custom_value_101;
            // if (process_data_101.diff_now >= 0) {
            //   custom_value_101 = `距现在：${process_data_101.format_string}`;
            // } else {
            //   custom_value_101 = `已过去：${process_data_101.format_string}`;
            // }
            // entity.custom_value_101 = custom_value_101;

            // console.log(JSON.stringify(entity));
          },
        });

        __this.aListTimer = aListTimer
      })
    },
    async getAllProductType() {
      let [err, res] = await awaitWrap(plat_dom_getAllProductType())
      console.log(res)
      if (err) {
        return
      }
      let productTypeList = res.producyTypes
      productTypeList.unshift({
        code: '',
        msg: '全部'
      })
      this.productTypeList = productTypeList
    },
    setTagType(val) {
      let type = ''
      const typeList = ['policy-label-1', 'policy-label-2', 'policy-label-3', 'policy-label-4', 'policy-label-5', 'policy-label-6']
      this.productTypeList.forEach((value, index) => {
        const codeList = value.code.split(',')
        if (codeList.indexOf(val) >= 0) {
          type = typeList[index]
        }
      })
      return type
    },

    toApplyCancelSeat(orderNo) {
      this.$router.push({
        name: 'air-ticket-admin-cancel-seat-handle',
        query: {
          orderNo: orderNo
        }
      });
    },

    //获取订单来源枚举值
     async getOrderSourceEnum() {
       let [err, res] = await awaitWrap(buyer_dom_manage_orderSourceEnumList())
       if (err) {
         return
       }
       this.orderSourceList = [...this.orderSourceList, ...res.res];
    }
  },
  watch: {
    '$route.query.ordersNo': function (newId) {
      //this.__data_bind_list()
    },
  },
  created() {
    this.deferInitDataCompleted = new Promise((resolve, reject) => {
      this.deferResolve = resolve
    })
  },
  async mounted() {
    this.getPartProductType()
    await this.getAllProductType();
    await this.getOrderSourceEnum();
    this.init();
    this._initTimer()

  },
  activated() {

  },
  deactivated() {
    if (this.aListTimer) {
      this.aListTimer.clear()
    }
  },
  destroyed() {
  },
}
